<template>
  <div class="excel-reload">
    <div class="excel-reload-header">
        <h2>סריקת קובץ אקסל</h2>
        <img @click="handle_open_upload_file" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fexcel_375x375.png?alt=media&token=7d34bce7-f1e7-44ca-ab7a-33656b632eb2">
    </div>
    <div v-if="data_result.length>0" v-loading="is_pending" class="excel-reload-content">
        <div class="excel-reload-content-header">
            <h2>תצוגה מקדימה</h2>
        </div>
        <div  class="excel-reload-content-data">
            <table>
                <tr>
                    <th>פריט</th>
                    <th>שם פריט</th>
                    <th>שם פריט לועזית</th>
                </tr>
                <template v-for="data in data_result" :key="data['פריט']">
                    <tr>
                        <td>{{data['פריט']}}</td>
                        <td>{{data['שם פריט']}}</td>
                        <td>{{data['שם פריט לועזית']}}</td>
                    </tr>
                </template>
            </table>
        </div>
        <div class="excel-reload-content-footer">
            <el-button @click="handle_submit" type="success" size="small">טעינה</el-button>
        </div>
    </div>
    <input ref="upload_ref" v-show="false" type="file" @change="handle_import_from_csv">
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import XLSX from "xlsx";
import {slide_pop_error,alert} from '../../../../../Methods/Msgs'
import {save_data_from_excel_in_db} from '../scripts/scripts'
export default {
    props:['data','reload_data'],
    emits:['reload_data'],
    setup(props,{emit}){

        const is_pending = ref(false)

        const upload_ref = ref()

        const data_result = ref([])
        const err_msg = ref('')

        const handle_open_upload_file = () => {
            upload_ref.value.value = null
            upload_ref.value.click()
        }

        const validation_duplicates = () => {
            const set = new Set()
            for(const data of data_result.value){
                if(!set.has(data['פריט'])){
                    set.add(data['פריט'])
                }
                else if(set.has(data['פריט'])){
                    err_msg.value = `מספר פריט ${data['פריט']} מופיע יותר מפעם אחת!`
                    return false
                }
                if(!set.has(data['שם פריט'])){
                    set.add(data['שם פריט'])
                }
                else if(set.has(data['שם פריט'])){
                    err_msg.value = `שם פריט ${data['שם פריט']} מופיע יותר מפעם אחת!`
                    return false
                }
                if(!set.has(data['שם פריט לועזית'])){
                    set.add(data['שם פריט לועזית'])
                }
                else if(set.has(data['שם פריט לועזית'])){
                    err_msg.value = `שם פריט בלועזית ${data['שם פריט לועזית']} מופיע יותר מפעם אחת!`
                    return false
                }
            }
            return true
        } 

        const validation_colums = () => {
            for(const data of data_result.value){
                const keys = Object.keys(data)
                if(!keys.includes('פריט')){
                    err_msg.value = 'עליך ספק קובץ אקסל עם עמודה בשם פריט!'
                    return false
                }
                if(!keys.includes('שם פריט')){
                    err_msg.value = 'עליך לספק קובץ אקסל עם עמודה בשם שם פריט!'
                    return false
                }
                if(!keys.includes('שם פריט לועזית')){
                    err_msg.value = 'עליך לספק קובץ אקסל עם עמודה בשם שם פריט!'
                    return false
                }

            }
            return true
        }

        const validation_missing_data = () => {
            for(const data of data_result.value){
                
                if(data['פריט']==''){
                    err_msg.value = 'חסר מידע לגבי ברקוד פריט מסויים בקובץ שניסית לטעון!'
                    return false
                }
                if(data['שם פריט']==''){
                    err_msg.value = 'חסר מידע לגבי שם פריט מסויים בקובץ שניסית לטעון!'
                    return false
                }
                if(data['שם פריט לועזית']==''){
                    err_msg.value = 'חסר מידע לגבי שם פריט בלועזית מסויים בקובץ שניסית לטעון!'
                    return false
                }
            }
            return true
        }

        const validation_if_exist_in_db = () => {
            const set = new Set()
            for(const data of props.data){
                set.add(data['פריט'])
                set.add(data['שם פריט'])
                set.add(data['שם פריט לועזית'])
            }
            for(const data of data_result.value){
                if(set.has(data['פריט'])){
                    err_msg.value = `הפריט ${data['פריט']} כבר קיים במערכת!`
                    return false
                }
                if(set.has(data['שם פריט'])){
                    err_msg.value = `הפריט ${data['שם פריט']} כבר קיים במערכת!`
                    return false
                }
                if(set.has(data['שם פריט לועזית'])){
                    err_msg.value = `הפריט ${data['שם פריט לועזית']} כבר קיים במערכת!`
                    return false
                }
            }
            return true
        }

        const handle_submit = async() => {
            if(!validation_colums()){
                slide_pop_error(err_msg.value)
            }
            else if(!validation_missing_data()){
                slide_pop_error(err_msg.value)
            }
            else if(!validation_duplicates()){
                slide_pop_error(err_msg.value)
            }
            else if(!validation_if_exist_in_db()){
                slide_pop_error(err_msg.value)
            }
            else{
                is_pending.value = true
                await save_data_from_excel_in_db(data_result.value)
                data_result.value = []
                is_pending.value = false
                alert("success","הצלחה","העדכון בוצע בהצלחה")
                .then(()=>{
                    emit('reload_data')
                })
            }
        }

          //טעינת פריטים מקובץ אקסל
        const handle_import_from_csv = (e)=>{
          is_pending.value = true
          var file = e.target.files[0];
          if (!file) return;
          var FR = new FileReader();
            FR.onload = async function(e) {
              var data = new Uint8Array(e.target.result);
              var workbook = XLSX.read(data, {type: 'array'});
              var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
              
              // header: 1 instructs xlsx to create an 'array of arrays'
              data_result.value = XLSX.utils.sheet_to_json(firstSheet, { raw: false });
              is_pending.value = false
              // data preview
                // selected_excel_data.value = result
                // show_excel_data_preview.value = true
                // upload.value.value=''
            };
            FR.readAsArrayBuffer(file);
        }



        return{
            handle_open_upload_file,
            handle_import_from_csv,
            handle_submit,
            upload_ref,
            data_result,
            err_msg,
            is_pending
        }
    }
}
</script>

<style scoped>
    .excel-reload{
        width: 100%;
        height: 100%;
    }
    .excel-reload-header{
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px 0;
    }
    .excel-reload-header img{
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
        user-select: none;
    }
    .excel-reload-content{
        width: 100%;
        height: calc(100% - 200px);
    }
    .excel-reload-content-header,.excel-reload-content-footer{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .excel-reload-content-data{
        width: 100%;
        height: calc(100% - 80px);
        overflow-y: auto;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    
</style>